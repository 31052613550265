// import React from "react";

// const AnimatedHero = () => {
//   return (
//     <div className="my-10 mt-20">
//       <div className="flex items-center justify-center h-full text-center space-y-8 px-4">
//         <div className=" grid md:grid-cols-2 grid-cols-1 text-justify">
//           <div className=" px-32">
//             <h1 className="text-6xl font-ppeditorial text-black mr-40 tracking-wider">
//               What do we do?
//             </h1>
//             <p className="text-6xl font-ppeditorial text-black mt-48 tracking-widest">
//               Who are we?
//               <p className=" font-light text-lg text-justify tracking-wide">
//                 At Unico, we’re a digital marketing agency focused on delivering
//                 creative, data-driven solutions that help brands grow. We
//                 specialize in social media, SEO, and web design, creating
//                 strategies that drive results and make your brand stand out.
//               </p>
//             </p>
//           </div>

//           <div className="flex flex-col text-justify ">
//             <div className="text-white mt-11 px-8 md:pr-40 bg-[#2B2B2B] py-4 rounded-3xl">
//               <p className="text-3xl font-normal tracking-wider font-ppeditorial mb-1">
//                 We Design
//               </p>
//               We create it all. Sit back, relax, and watch your vision come to
//               life. Stunning design, clear messaging, and flawless execution.
//             </div>
//             <div className="text-white mt-11 px-8 md:pr-40 bg-[#2B2B2B] py-4 rounded-3xl">
//               <p className="text-3xl font-ppeditorial font-normal tracking-wider mb-1">
//                 We Build
//               </p>
//               Built for the future. We turn complex ideas into seamless,
//               scalable digital experiences
//             </div>
//             <div className="text-white mt-11 px-8 md:pr-40 bg-[#2B2B2B] py-4 rounded-3xl">
//               <p className="text-3xl font-normal tracking-wider font-ppeditorial mb-1">
//                 We Grow
//               </p>
//               Marketing that works. We connect your brand with the right
//               audience through strategy, creativity, and impact
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AnimatedHero;


import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const AnimatedHero = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
    });
  }, []);

  return (
    <div className="my-10 mt-20">
      <div className="flex items-center justify-center h-full text-center space-y-8 px-4">
        <div className="grid md:grid-cols-2 grid-cols-1 text-justify">
          <div className="md:px-32">
            <h1 className="text-6xl font-ppeditorial text-black md:mr-40 tracking-wider text-center md:text-justify">
              What do we do?
            </h1>
            <div className="text-6xl font-ppeditorial text-black mt-48 tracking-wide">
              Who are we?
              <p className="mt-2 font-light text-lg text-justify tracking-wide">
                At Unico, we’re a digital marketing agency focused on delivering
                creative, data-driven solutions that help brands grow. We
                specialize in social media, SEO, and web design, creating
                strategies that drive results and make your brand stand out.
              </p>
            </div>
          </div>

          <div className="flex flex-col text-justify">
            {/* Box 1 */}
            <div
              className="text-white mt-11 px-8 md:pr-40 bg-[#2B2B2B] py-4 rounded-3xl"
              data-aos="fade-up"
            >
              <p className="text-3xl font-normal tracking-wider font-ppeditorial mb-1">
                We Design
              </p>
              We create it all. Sit back, relax, and watch your vision come to
              life. Stunning design, clear messaging, and flawless execution.
            </div>

            {/* Box 2 */}
            <div
              className="text-white mt-11 px-8 md:pr-40 bg-[#2B2B2B] py-4 rounded-3xl"
              data-aos="fade-up"
              data-aos-delay="1000"
            >
              <p className="text-3xl font-ppeditorial font-normal tracking-wider mb-1">
                We Build
              </p>
              Built for the future. We turn complex ideas into seamless,
              scalable digital experiences
            </div>

            {/* Box 3 */}
            <div
              className="text-white mt-11 px-8 md:pr-40 bg-[#2B2B2B] py-4 rounded-3xl"
              data-aos="fade-up"
              data-aos-delay="1500"
            >
              <p className="text-3xl font-normal tracking-wider font-ppeditorial mb-1">
                We Grow
              </p>
              Marketing that works. We connect your brand with the right
              audience through strategy, creativity, and impact
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnimatedHero;
