export default function Footer() {
  return (
    <footer className="bg-white text-black py-8 px-4 lg:px-20">
      <div className="flex flex-col md:flex-row justify-around items-center gap-20">
        <div className="flex justify-center items-center">
          <img src="/logo.png" alt="UNICO Logo" width={100} height={40} />
        </div>

        <div className="flex flex-col md:flex-row justify-between gap-8 md:gap-40 w-full md:w-auto">
          <div>
            <h3 className="font-semibold mb-2">CONTENT</h3>
            <ul className="font-montserrat">
              <li>Home</li>
              <li>About</li>
              <li>Services</li>
              <li>Portfolio</li>
            </ul>
          </div>

          <div>
  <h3 className="font-semibold mb-2">Follow Us</h3>
  <ul className="font-montserrat space-y-2">
    <li className="flex items-center space-x-2">
      <i className="fab fa-instagram text-black"></i>
      <a 
        href="https://instagram.com/" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-black hover:text-gray-700"
      >
        Instagram
      </a>
    </li>
    <li className="flex items-center space-x-2">
      <i className="fab fa-linkedin text-black"></i>
      <a 
        href="https://linkedin.com/in/" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-black hover:text-gray-700"
      >
        LinkedIn
      </a>
    </li>
    <li className="flex items-center space-x-2">
      <i className="fab fa-facebook text-black"></i>
      <a 
        href="https://facebook.com/" 
        target="_blank" 
        rel="noopener noreferrer" 
        className="text-black hover:text-gray-700"
      >
        Facebook
      </a>
    </li>
  </ul>
</div>


          <div>
            <h3 className="font-semibold mb-2">CONTACT</h3>
            <ul className="font-montserrat">
              <li>+91 8105459006</li>
              <li>contact@unicostudios.in</li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
