import React from "react";

const HeroImageCards = () => {
  return (
    <div className="p-5 md:p-10">
      <div className="grid grid-cols-1 gap-5 md:gap-4 lg:grid-cols-2 xl:grid-cols-3">
        <div className="box-border col-span-1 lg:col-span-1 lg:row-span-2 hover:scale-105 duration-200 border border-b-8 border-black rounded-3xl flex justify-center items-center p-10 px-20">
          <div className=" flex flex-col justify-center items-center gap-5">
            <h1 className=" font-ppeditorial uppercase text-4xl">Ui Ux</h1>
            <div className=" w-44 h-44 rounded-full border border-black flex justify-stretch items-center content-stretch overflow-hidden">
              <img
                src="./uiuxcard.png"
                alt=" ui ux card"
                className=" h-64 w-96 "
              />
            </div>
            <div className=" text-center">
              Enhance user experiences with intuitive UI/UX design. We focus on
              crafting visually appealing websites and apps that engage and
              retain users.
            </div>
          </div>
        </div>

        <div className="w-full box-border col-span-1 lg:col-span-1 relative hover:scale-105 duration-200 border border-black rounded-3xl flex items-start p-10 border-b-8 ">
          <div className=" flex flex-col justify-center items-start text-justify gap-3">
            <h1 className=" font-ppeditorial text-4xl tracking-wider font-normal">
              40+ <br />
              Trusted Clients
            </h1>

            <div className=" font-light tracking-widest">
              In Tech, Lifestyle, and Beyond
            </div>
          </div>
        </div>

        <div className="w-full box-border col-span-1 lg:col-span-1 row-span-2 relative hover:scale-105 duration-200 border border-b-8 border-black rounded-3xl flex justify-center items-center p-10 px-16">
          <div className=" flex flex-col justify-center items-center gap-5">
            <h1 className=" font-ppeditorial uppercase text-4xl">Marketing</h1>
            <div className=" w-44 h-44 rounded-full border border-black flex justify-stretch items-center content-stretch overflow-hidden">
              <img
                src="./marketing.png"
                alt=" ui ux card"
                className=" h-64 w-96 "
              />
            </div>
            <div className=" text-center">
              Unlock your brand’s potential with tailored digital marketing
              strategies. From SEO and social media to email marketing, we drive
              traffic and engagement that converts.
            </div>
          </div>
        </div>

        <div className="w-full box-border col-span-1 lg:col-span-1 relative hover:scale-105 duration-200 border border-black rounded-3xl flex items-center p-10 border-b-8">
          <div className=" flex flex-col justify-center items-start text-justify gap-3">
            <h1 className=" font-ppeditorial text-4xl tracking-wider font-normal">
              12+ <br />
              Experts
            </h1>

            <div className=" font-light tracking-widest">
              A talented team turning creative ideas into reality
            </div>
          </div>
        </div>

        {/* <div className="col-span-1 lg:col-span-1 relative hover:scale-105 duration-200">
          <img
            src={""}
            alt="Destination"
            className="w-full h-auto border border-black rounded-2xl hover:cursor-pointer"
          />
          <div className="absolute inset-0 h-[0%] left-0 right-0 p-4 text-white hover:cursor-pointer">
            <span className="font-bold text-2xl border bg-orange-100 text-black px-4 rounded-full">
              Manali
            </span>
            <div className=" text-lg"> Properties</div>
          </div>
        </div> */}

        <div className="w-full box-border col-span-1 lg:col-span-1 relative hover:scale-105 duration-200 border border-b-8 border-black rounded-3xl flex justify-center items-center p-10 px-20">
          <div className=" flex flex-col justify-center items-center gap-5">
            <h1 className=" font-ppeditorial text-4xl">3D Design</h1>
            <div className=" w-24 h-24 rounded-full border border-black flex justify-stretch items-center content-stretch overflow-hidden">
              <img
                src="./3d-design.png"
                alt=" ui ux card"
                className=" h-64 w-96"
              />
            </div>
            <div className=" text-center">
              Bring your concepts to life with our 3D design services. From
              realistic modeling to engaging animations, we create immersive
              visuals that captivate audiences.
            </div>
          </div>
        </div>

        <div className="w-full box-border col-span-1 lg:col-span-1 row-span-1 relative hover:scale-105 duration-200 border border-b-8 border-black rounded-3xl flex justify-center items-center p-10 px-20">
          <div className=" flex flex-col justify-center items-center gap-5">
            <h1 className=" font-ppeditorial uppercase text-4xl">Branding</h1>
            <div className=" w-44 h-44 rounded-full border border-black flex justify-stretch items-center content-stretch overflow-hidden">
              <img
                src="./branding.png"
                alt=" branding card"
                className=" h-64 w-96 "
              />
            </div>
            <div className=" text-center">
              Create a unique brand identity that stands out. We offer logo
              design and brand strategy to ensure consistency and connection
              with your audience.
            </div>
          </div>
        </div>

        <div className="w-full box-border col-span-1 lg:col-span-1 relative hover:scale-105 duration-200 border border-b-8 border-black rounded-3xl flex justify-center items-center p-10 px-16">
          <div className=" flex flex-col justify-center items-center gap-5">
            <h1 className=" font-ppeditorial text-4xl">Content</h1>
            <div className=" w-24 h-24 rounded-full border border-black flex justify-stretch items-center content-stretch overflow-hidden">
              <img
                src="./content.png"
                alt=" ui ux card"
                className="h-28 w-full"
              />
            </div>
            <div className=" text-center">
              Tell your brand story through compelling content. Our services
              include copywriting and visual content creation that resonate with
              your target audience.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroImageCards;
