import React, { useRef } from "react";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";

const Carousal2 = () => {
  const projects = [
    {
      title: "Haze Productions",
      description: "Web Design  Web Development  SMM",
      image: "/latest_work_img1.png",
    },
    {
      title: "Tharavadu",
      description: "Web Design  Branding",
      image: "/latest_work_img2.png",
    },
    {
      title: "SmartWealth",
      description: "Web Design  Web Development",
      image: "/workimg3.png",
    },
    {
      title: "SmartWealth",
      description: "Web Design  Web Development",
      image: "/latest_work_img1.png",
    },
    {
      title: "SmartWealth",
      description: "Web Design  Web Development",
      image: "/workimg3.png",
    },
  ];

  const scrollRef = useRef(null);

  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -300, behavior: "smooth" });
  };

  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 300, behavior: "smooth" });
  };

  return (
    <div className="my-8 bg-[#2B2B2B] rounded-3xl p-8 relative">
      <div className="flex justify-between items-center mb-8">
        <h2 className="text-white text-4xl font-normal font-ppeditorial tracking-wider">
          Our Work
        </h2>
        <a
          href="https://docs.google.com/presentation/d/1TAUCnMJaMtRvGP6U3VLObL_9H7T0lL6_TGZUUKAjZbo/edit?usp=sharing"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-white text-black px-6 py-2 rounded-full text-base hover:bg-gray-100 md:text-justify text-center"
        >
          Explore more of our work
        </a>
      </div>

      <div className="relative">
        {/* Left Button */}
        <button
          onClick={scrollLeft}
          className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-transparent text-white p-4 z-10 hover:text-gray-400"
        >
          <FaCircleArrowLeft className="w-8 h-8" />
        </button>

        {/* Carousel */}
        <div
          ref={scrollRef}
          className="flex overflow-x-scroll snap-x snap-mandatory space-x-6 mb-3 scrollbar-hide"
        >
          {projects.map((project, index) => (
            <div
              key={index}
              className="flex-shrink-0 rounded-xl overflow-hidden snap-center"
            >
              <img
                src={project.image}
                alt="projects"
                className="border border-black rounded-3xl w-[480px] md:w-[350px] lg:w-[400px] h-[300px]"
              />
              <div className="flex flex-col justify-between p-4">
                <h3 className="text-3xl font-light font-ppeditorial tracking-widest text-white">
                  {project.title}
                </h3>
                <div className="text-white flex items-center justify-between">
                  <div className="flex gap-6">
                    <p className="text-white text-sm font-light">
                      {project.description.split("  ")[0]}
                    </p>
                    <p className="text-white text-sm font-light">
                      {project.description.split("  ")[1]}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Right Button */}
        <button
          onClick={scrollRight}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-transparent text-white p-4 z-10 hover:text-gray-400"
        >
          <FaCircleArrowRight className="w-8 h-8" />
        </button>
      </div>
    </div>
  );
};

export default Carousal2;
