import React, { useState } from "react";
import { FaCircleArrowRight } from "react-icons/fa6";

const Pricing = () => {
  const [flippedCard, setFlippedCard] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const toggleFlip = (cardIndex) => {
    setFlippedCard((prev) => (prev === cardIndex ? null : cardIndex));
  };

  const togglePopup = () => {
    setIsPopupVisible((prev) => !prev);
  };

  const CardContent = ({
    cardIndex,
    title,
    description,
    price,
    features,
    isFlipped,
  }) => (
    <div
      className={`relative h-[600px] border rounded-3xl shadow-lg p-8 flex flex-col justify-between transform transition-transform duration-500 ${
        isFlipped ? "rotate-y-180" : ""
      }`}
      style={{ perspective: "1000px" }}
    >
      {!isFlipped ? (
        <div className="absolute inset-0 flex flex-col p-8 backface-hidden">
          <h3 className="text-4xl font-normal mb-10 font-ppeditorial tracking-wider">
            {title}
          </h3>
          <div className="mt-auto">
            <p className="text-black mb-6 text-2xl tracking-wider">
              {description}
            </p>
            <button
              onClick={() => toggleFlip(cardIndex)}
              aria-label={`Check Price for ${title}`}
              className="flex items-center justify-between gap-3 text-black border border-black rounded-full py-2 px-4 hover:shadow-lg hover:bg-gray-100 transition"
            >
              Check Price <FaCircleArrowRight className="text-2xl" />
            </button>
          </div>
        </div>
      ) : (
        <div className="absolute inset-0 flex flex-col p-8 backface-hidden bg-white rounded-3xl justify-between">
          <h3 className="text-5xl font-normal font-ppeditorial text-center tracking-wider">
            {price}
          </h3>
          <ul className="text-black mt-4 space-y-2 list-disc">
            {features.map((feature, idx) => (
              <li className=" text-xl font-normal py-3"key={idx}>{feature}</li>
            ))}
          </ul>
          <button
            onClick={togglePopup}
            aria-label={`Select ${title}`}
            className="flex items-center justify-between mt-5 text-black border border-black rounded-full py-2 px-4 hover:shadow-lg hover:bg-gray-100 transition"
          >
            I Like This <FaCircleArrowRight className="text-2xl" />
          </button>
        </div>
      )}
    </div>
  );

  return (
    <div className="py-16 px-6 bg-white">
      <div className="mb-12 text-start tracking-widest">
        <h2 className="text-5xl font-normal mb-2 font-ppeditorial">
          Pricing Plans
        </h2>
        <p className="text-gray-600">
          Tailored solutions to fit your business needs and budget.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <CardContent
          cardIndex={1}
          title="Business Builder Package"
          description="A solid option for small businesses wanting to scale their online presence."
          price="$426.00/m"
          features={[
            "Branding",
            "Website Design (Up to 5 Pages)",
            "SMM (2 Platforms)",
            "Basic SMM Strategy",
            "Basic SEO Services",
          ]}
          isFlipped={flippedCard === 1}
        />
        <CardContent
          cardIndex={2}
          title="Professional Package"
          description="Best for businesses looking for a professional online presence and higher engagement."
          price="$1310.00/m"
          features={[
            "Comprehensive Branding",
            "Website Design (Custom & Scalable)",
            "SMM (All Major Platforms)",
            "Advanced SMM Strategy with Analytics",
            "Advanced SEO Optimization + Backlinking",
          ]}
          isFlipped={flippedCard === 2}
        />
        <div className="relative h-[600px] border rounded-3xl shadow-lg p-8 flex flex-col justify-between">
          <div className="absolute inset-0 flex flex-col p-8">
            <h3 className="text-4xl font-normal mb-10 font-ppeditorial tracking-wider">
              Tailored for You
            </h3>
            <div className="mt-auto">
              <p className="text-black mb-6 text-2xl tracking-wider">
                A personalized solution to fit your unique business needs.
                Contact us for a custom quote.
              </p>
              <a
                href="https://calendly.com/sreehari-unicostudios/30min"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center justify-between gap-3 text-black border border-black rounded-full py-2 px-4 hover:shadow-lg hover:bg-gray-100 transition"
              >
                Schedule A Call <FaCircleArrowRight className="text-2xl" />
              </a>
            </div>
          </div>
        </div>
      </div>

      {isPopupVisible && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-black bg-opacity-10 backdrop-blur-md"></div>
          <div className="relative bg-white p-8 rounded-3xl shadow-lg max-w-md w-full z-10">
            <button
              onClick={togglePopup}
              aria-label="Close Popup"
              className="absolute top-3 right-3 text-gray-500 hover:text-black text-2xl"
            >
              &times;
            </button>
            <h2 className="text-3xl font-bold mb-4 text-center">
              Great Choice!
            </h2>
            <p className="text-gray-600 mb-4 text-center">
              Let’s bring your vision to life. Fill in your details, and we’ll
              get started!
            </p>
            <form className="space-y-4">
              {["Name", "Email Address", "Contact Number", "Business Name"].map(
                (placeholder, idx) => (
                  <input
                    key={idx}
                    type="text"
                    placeholder={placeholder}
                    className="w-full border border-gray-300 rounded px-4 py-2"
                  />
                )
              )}
              <div className="flex justify-center space-x-4">
                {["Business Package", "Professional Package"].map(
                  (packageType, idx) => (
                    <button
                      key={idx}
                      type="button"
                      className="py-2 px-6 border border-black rounded-full hover:bg-gray-100"
                    >
                      {packageType}
                    </button>
                  )
                )}
              </div>
              <button
                type="submit"
                className="w-full py-2 bg-black text-white rounded-full hover:opacity-90"
              >
                Get Started
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Pricing;
