import AnimatedHero from "./components/animatedhero";
import Carousal1 from "./components/carousal1";
import Carousal2 from "./components/carousal2";
import Footer from "./components/footer";
import Hero from "./components/hero";
import HeroImageCards from "./components/heroimagecard";
import Navbar from "./components/navbar";
import Pricing from "./components/pricing";
import Subscribe from "./components/subscribe";
import "./index.css";
import CustomCursor from "./components/CustomCursor";
function App() {
  return (
    <div>
      <Navbar />
      <div id="home">
        <Hero />
        <Carousal1 />
      </div>
      <div id="aboutus">
        <AnimatedHero />
      </div>
      <div id="services">
        <HeroImageCards />
      </div>
      <div id="portfolio">
        <Carousal2 />
      </div>
      <div id="pricing">
        <Pricing />
      </div>
      <div id="subscribe">
        <Subscribe />
      </div>
      <div id="footer">
        <Footer />
      </div>
      <CustomCursor />
    </div>
  );
}


export default App;
