import React, { useEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import { MdClose } from "react-icons/md";
import { FaLinkedinIn, FaWhatsapp, FaFacebook } from "react-icons/fa";

const Navbar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [activeSection, setActiveSection] = useState("");

  // Handle scroll-based active section detection
  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll("div[id]"); // All sections with IDs
      let current = "";

      sections.forEach((section) => {
        const sectionTop = section.offsetTop - 100; // Offset to account for sticky navbar height
        if (window.scrollY >= sectionTop) {
          current = section.getAttribute("id");
        }
      });

      setActiveSection(current);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="w-full h-24 mx-auto my-0 sticky top-0 z-50 bg-transparent flex md:justify-around justify-between px-10 items-center font-titleFont">
      <div className="flex flex-row justify-center gap-2 items-center hover:cursor-pointer">
        <img src="/logo.png" alt="Logo" width={110} height={110} />
      </div>

      <div>
        <ul className="hidden md:flex space-x-8 bg-[#2B2B2B] py-2 px-6 rounded-full">
          <li
            className={` ${
              activeSection === "home" ? "bg-white text-black rounded-full px-4 py-1" : "text-white font-medium px-4 py-1 rounded-full hover:bg-white hover:text-[#2B2B2B] transition hover:cursor-pointer"
            }`}
          >
            <a href="#home">Home</a>
          </li>
          <li
            className={` ${
              activeSection === "aboutus" ? "bg-white text-black rounded-full px-4 py-1" : "text-white font-medium px-4 py-1 rounded-full hover:bg-white hover:text-[#2B2B2B] transition hover:cursor-pointer"
            }`}
          >
            <a href="#aboutus">About Us</a>
          </li>
          <li
            className={` ${
              activeSection === "services" ? "bg-white text-black rounded-full px-4 py-1" : "text-white font-medium px-4 py-1 rounded-full hover:bg-white hover:text-[#2B2B2B] transition hover:cursor-pointer"
            }`}
          >
            <a href="#services">Services</a>
          </li>
          <li
            className={` ${
              activeSection === "portfolio" ?"bg-white text-black rounded-full px-4 py-1" : "text-white font-medium px-4 py-1 rounded-full hover:bg-white hover:text-[#2B2B2B] transition hover:cursor-pointer"
            }`}
          >
            <a href="#portfolio">Portfolio</a>
          </li>
        </ul>

        <span
          onClick={() => setShowMenu(!showMenu)}
          className="text-3xl md:hidden bg-[#2B2B2B] w-14 h-14 flex items-center justify-center rounded-full text-white cursor-pointer"
        >
          <FiMenu />
        </span>

        {showMenu && (
          <div className="w-[80%] h-screen overflow-scroll absolute top-0 -left-3 bg-gray-300 p-4">
            <div className="flex flex-col gap-8 py-2 pl-3">
              <div className="flex flex-row gap-4 items-center hover:cursor-pointer">
                <img src="/logo.png" alt="Logo" width={100} height={100} />
              </div>

              <ul className="flex flex-col gap-4">
                <li
                  className={`text-base font-normal text-black tracking-wide hover:text-gray-700 hover:underline cursor-pointer duration-300 hover:cursor-pointer ${
                    activeSection === "home" ? "text-black font-semibold" : ""
                  }`}
                >
                  <a href="#home" onClick={() => setShowMenu(false)}>
                    Home
                  </a>
                </li>
                <li
                  className={`text-base font-normal text-black tracking-wide hover:text-gray-700 hover:underline cursor-pointer duration-300 hover:cursor-pointer ${
                    activeSection === "aboutus" ? "text-black font-semibold" : ""
                  }`}
                >
                  <a href="#aboutus" onClick={() => setShowMenu(false)}>
                    About Us
                  </a>
                </li>
                <li
                  className={`text-base font-normal text-black tracking-wide hover:text-gray-700 hover:underline cursor-pointer duration-300 hover:cursor-pointer ${
                    activeSection === "services" ? "text-black font-semibold" : ""
                  }`}
                >
                  <a href="#services" onClick={() => setShowMenu(false)}>
                    Services
                  </a>
                </li>
                <li
                  className={`text-base font-normal text-black tracking-wide hover:text-gray-700 hover:underline cursor-pointer duration-300 hover:cursor-pointer ${
                    activeSection === "portfolio" ? "text-black font-semibold" : ""
                  }`}
                >
                  <a href="#portfolio" onClick={() => setShowMenu(false)}>
                    Portfolio
                  </a>
                </li>
              </ul>

              <div className="flex flex-col gap-4">
                <h2 className="text-gray-700 text-base uppercase mt-3">
                  Find me on
                </h2>
                <div className="flex gap-4 hover:cursor-pointer">
                  <FaWhatsapp
                    className="text-gray-700 hover:text-xl hover:font-bold hover:text-black"
                    onClick={() => window.open("https://wa.me/")}
                  />
                  <FaFacebook
                    className="text-gray-700 hover:text-xl hover:font-bold hover:text-black"
                    onClick={() => window.open("https://facebook.com/")}
                  />
                  <FaLinkedinIn
                    className="text-gray-700 hover:text-xl hover:font-bold hover:text-black"
                    onClick={() => window.open("https://www.linkedin.com/in/")}
                  />
                </div>
              </div>

              <span
                onClick={() => setShowMenu(false)}
                className="absolute top-4 right-4 text-gray-400 hover:text-purple-500 text-2xl cursor-pointer"
              >
                <MdClose />
              </span>
            </div>
          </div>
        )}
      </div>

      {/* Contact Us Button */}
      <a
        href="https://wa.me/+918105459006"
        target="_blank"
        rel="noopener noreferrer"
        className="text-black hover:cursor-pointer bg-white py-2 px-8 rounded-full border border-black border-b-4 font-medium hover:bg-gray-200 transition md:block hidden"
      >
        Contact Us
      </a>
    </div>
  );
};

export default Navbar;
