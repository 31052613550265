import React from "react";
import { FaCircleArrowRight } from "react-icons/fa6";

const Carousal1 = () => {
  return (
    <div className="my-4 bg-[#2B2B2B] rounded-3xl p-6">
      <h2 className="text-white text-xl font-light mb-4">Our Latest Work</h2>
      <div className="flex flex-wrap md:flex-nowrap gap-6 overflow-x-scroll md:overflow-hidden snap-x snap-mandatory scrollbar-hide">
        <div
          className="relative w-full md:w-1/2 h-[400px] flex-shrink-0 rounded-xl overflow-hidden bg-cover bg-center snap-center"
          style={{ backgroundImage: `url('/latest_work_img1.png')` }}
        >
          {/* <div className="absolute inset-0 bg-black bg-opacity-50"></div> */}
          <div className="absolute inset-0 flex flex-col justify-between p-4">
            <div className="flex items-center justify-start gap-32">
              <p className="text-white text-sm font-light">Website Design</p>
              <p className="text-white text-sm font-light">
                Website Development
              </p>
            </div>
            <div className="text-white flex items-center justify-between">
              <h3 className="text-xl md:text-4xl mb-2 font-ppeditorial font-thin uppercase">
                HAZE <br />
                PRODUCTIONS
              </h3>

              <a
                href="https://docs.google.com/presentation/d/1TAUCnMJaMtRvGP6U3VLObL_9H7T0lL6_TGZUUKAjZbo/edit?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-white text-black px-4 py-2 rounded-full text-sm md:text-xl hover:bg-gray-300 flex items-center justify-center gap-3"
              >
                View Work <FaCircleArrowRight className="w-5 h-5 inline" />
              </a>
            </div>
          </div>
        </div>

        <div
          className="relative w-full md:w-1/2 h-[400px] flex-shrink-0 rounded-xl overflow-hidden bg-cover bg-center snap-center"
          style={{ backgroundImage: `url('/latest_work_img2.png')` }}
        >
          {/* <div className="absolute inset-0 bg-black bg-opacity-50"></div> */}
          <div className="absolute inset-0 flex flex-col justify-between p-4">
            <div className=" flex items-center justify-start gap-32">
              <p className="text-white text-sm font-light">Website Design</p>
              <p className="text-white text-sm font-light">
                Website Development
              </p>
            </div>
            <div className="text-white flex items-center justify-between mr-5">
              <h3 className="text-xl md:text-4xl mb-2 font-ppeditorial font-thin uppercase">
                SMART <br />
                WEALTH.AI
              </h3>
              <a
                href="https://docs.google.com/presentation/d/1TAUCnMJaMtRvGP6U3VLObL_9H7T0lL6_TGZUUKAjZbo/edit?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-white text-black px-4 py-2 rounded-full text-sm md:text-xl hover:bg-gray-300 flex items-center justify-center gap-3"
              >
                View Work <FaCircleArrowRight className="w-5 h-5 inline" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousal1;
