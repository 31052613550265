import React from "react";

const Hero = () => {
  return (
    <div className="mb-40">
      <div className="relative z-10 flex flex-col items-center justify-center h-full text-center space-y-8 px-4">
        <h1 className="text-5xl md:text-9xl font-normal font-ppeditorial tracking-wider mt-20">
          Intentional <span className=" font-ppeditorial italic">Impact</span>
        </h1>
        <div className="flex md:flex-row flex-col text-base lg:text-xl mx-auto text-black text-justify  font-montserrat">
          <div className=" flex-col pr-5 md:mr-5 text-center md:text-justify mb-5">
            <p>Digital Strategy</p>
            <p>Creative Impact</p>
            <p>Real Results</p>
          </div>
          <p className=" text-base lg:text-lg max-w-xl mx-auto text-black md:pr-40 ">
          From campaigns to design, we create strategies that engage and visuals that captivate. Elevate your brand with a complete digital experience.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Hero;
