import React from "react";
import { FaCircleArrowRight } from "react-icons/fa6";

const Subscribe = () => {
  return (
    <div className="relative flex justify-center items-center p-10 max-w-full w-full bg-gradient-to-r from-[#313131] to-[#2b2b2b] rounded-3xl overflow-hidden">
      {/* <div className="absolute right-1 bottom-0 rounded-full w-60 h-60 bg-[#AFB2B2FF] blur-3xl"></div> */}

      <div className="absolute top-[-50px] right-[-100px] rounded-full w-80 h-80 bg-[#696b6b] blur-3xl"></div>

      <div className="absolute bottom-[-80px] left-[-100px] rounded-full w-80 h-80 bg-[#696b6b] blur-3xl"></div>

      <div className="text-center py-10 z-50">
        <h1 className="text-white font-ppeditorial text-2xl tracking-widest md:text-start md:text-6xl mb-8">
          Let's Amplify, <br />{" "}
          <p className="md:pt-4 md:pl-20">Your Brand's Impact</p>
        </h1>

        <div className="flex justify-between items-center gap-1 md:gap-0 bg-white tracking-widest rounded-full shadow-lg p-1 md:mx-32 px-2 md:px-4">
          <a
            href="https://calendly.com/sreehari-unicostudios/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="text-black font-normal md:pl-5"
          >
            Schedule A Call 
          </a>

          <a
            href="https://calendly.com/sreehari-unicostudios/30min"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-black flex gap-2 items-center text-white font-light rounded-full border-2 p-1 px-3 border-white shadow-lg hover:shadow-xl hover:bg-white hover:border-black hover:text-black duration-300">
              Let's Go <FaCircleArrowRight className="inline" />
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
